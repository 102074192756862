<template>
  <div :id="prefixId + '-gis3dViewer'" class="gis3DViewer" ref="gis">
    <div :id="prefixId + '-viewDiv'" class="viewDiv3D"></div>
    <div :id="prefixId + '-elevationDiv'" class="elevationDiv esri-widget">
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" :id="prefixId + '-elevationInput'" v-on:change="updateElevation" checked="yes">
        <!-- <input type="checkbox" class="custom-control-input" :id="prefixId + '-elevationInput'" checked="yes" v-on:change="updateElevation"> -->
        <label class="custom-control-label" :for="prefixId + '-elevationInput'">Elevation</label>
      </div>
    </div>
    <div :id="prefixId+'-screenshotBtnDiv'" class="screenshotBtnDiv esri-widget">
      Start to screenshot by clicking the button below:
      <br>
      <br>
      <button
        :id="prefixId + '-screenshotBtn'"
        class="esri-button"
        aria-label="Select screenshot area"
        title="Select screenshot area"
        @click="screenshotBtnClick">
        Capture
      </button>
    </div>
    <div :id="prefixId+'-screenshotDiv'" class="screenshotDiv hide">
      <img :id="prefixId+'-screenshotImage'" class="js-screenshot-image" />
      <div>
        <label>Set a text to be displayed on the image: </label>
        <input type="text" placeholder="Image text" :id="prefixId+'-textInput'" autofocus />
      </div>
      <button
        :id="prefixId+'-downloadBtn'"
        class="btn btn-primary"
        aria-label="Download image"
        title="Download image">
        Download image
      </button>
      <button
        :id="prefixId+'-closeBtn'"
        class="btn btn-secondary"
        aria-label="Back to webscene"
        title="Back to webscene"
        @click="screenshotCloseClick">
        Back to webscene
      </button>
    </div>
    <div :id="prefixId+'-maskDiv'" class="hide maskDiv screenshotCursor"></div>
    <div :id="prefixId+'-fullscreenDiv'" class="fullscreenDiv"></div>
    <div :id="prefixId+'-environmentDiv'" class="environmentDiv esri-widget" >
      <div class="row">
        <div class="col-4">
          <label>Time&nbsp;of&nbsp;Day</label>
        </div>
        <br>
        <br>
        <div class="col-8">
          <select :id="prefixId+'-timeOfDaySelect'" class="form-control form-control-sm" v-on:change="updateTimeOfDay">
            <option value="1">Morning</option>
            <option value="2">Noon</option>
            <option value="3">Afternoon</option>
            <option value="4">Evening</option>
          </select>
        </div>
      </div>
      <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" :id="prefixId+'-directShadowsInput'" checked="yes" v-on:change="updateDirectShadows">
        <label class="custom-control-label" for="directShadowsInput">Show Shadows</label>
      </div>
    </div>
    <div :id="prefixId+'-opacitySliderDiv'" class="opacitySliderDiv esri-widget">
      <label><small>Ground Transparency</small></label>
      <div class="row container justify-content-center m-0">
        <div class="col-sm-auto p-0">
          <small>0</small>
        </div>
        <div class="col-sm-auto pl-2 pr-2">
          <input type="range" min="0" max="100" step="1" v-model="opacityValue" @change="setOpacity" class="form-range">
        </div>
        <div class="col-sm-auto p-0">
          <small>100</small>
        </div>
      </div>
    </div>
    <div :id="prefixId+'-discussionDiv'" class="discussionDiv esri-widget" >
      <NewDiscussion :mapContainer="prefixId + '-gis3dViewer'"></NewDiscussion>
    </div>
    <button :id="prefixId+'-discussionToolFullView'"  @click="openDiscussionToolFullView()" class="btn btn-dark esri-widget discussionToolFullView" title="See Discussions"><i class="fa fa-comments"></i></button>
    <button :id="prefixId+'-discussionToolRefresh'"  @click="refreshDiscussions()" class="btn btn-dark esri-widget discussionToolRefresh" title="Refresh Discussions"><i class="fa fa-sync-alt"></i></button>
    <button :id="prefixId+'-FAQButton'"  @click="modalfaqShow = true" class="btn btn-dark esri-widget custom-faq-widget" title="See FAQ"><i class="fa fa-question-circle"></i></button>
    <div :id="prefixId+'-slidesDiv'" class="slidesDiv"></div>

    <b-modal v-model="modalfaqShow" size="lg">
      <!-- <img class="img-fluid" src="sampleImage.jpg" /> -->
      <iframe
        src="./GWH_3DWebPortalUserGuide.pdf"
        width="100%"
        height="700"
        style="border: none; overflow: hidden;"
      />
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="modalfaqShow=false"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>

</template>
<script>
/*eslint-disable*/
  import WebScene from '@arcgis/core/WebScene';
  import SceneView from '@arcgis/core/views/SceneView';
  import esriConfig from '@arcgis/core/config';
  import esriId from "@arcgis/core/identity/IdentityManager";
  import OAuthInfo from '@arcgis/core/identity/OAuthInfo';
  import Portal from '@arcgis/core/portal/Portal';
  import PortalItem from '@arcgis/core/portal/PortalItem';
  import Expand from "@arcgis/core/widgets/Expand";
  import Fullscreen from "@arcgis/core/widgets/Fullscreen";
  import Home from "@arcgis/core/widgets/Home";
  import Search from "@arcgis/core/widgets/Search";
  import LayerList from "@arcgis/core/widgets/LayerList";
  import Legend from "@arcgis/core/widgets/Legend";
  import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
  import Slice from "@arcgis/core/widgets/Slice";
  import {watch} from "@arcgis/core/core/watchUtils";
  import DirectLineMeasurement3D from "@arcgis/core/widgets/DirectLineMeasurement3D";
  import AreaMeasurement3D from "@arcgis/core/widgets/AreaMeasurement3D";
  import '@arcgis/core/assets/esri/themes/dark/main.css';
  import NewDiscussion from './CommentingTool/NewDiscussion.vue';
  import {baseApiUrl, appBaseUrl} from "../../config.js";
  import { ClassBreaksRenderer, UniqueValueRenderer, RasterColormapRenderer, RasterStretchRenderer, RasterShadedReliefRenderer, VectorFieldRenderer } from "@arcgis/core/rasterRenderers";
  import ImageryTileLayer from "@arcgis/core/layers/ImageryTileLayer";
  import TileLayer from "@arcgis/core/layers/TileLayer";
  import ImageryLayer from "@arcgis/core/layers/ImageryLayer";
  import MapImageLayer from "@arcgis/core/layers/MapImageLayer";

  export default {
    name: 'GIS3DViewer',
    props: {
      appId: String,
      webSceneId: String
    },
    components: {
      NewDiscussion
    },
    data() {
      return {
        prefixId: null,
        portalURL: "https://au1gis.solutions.arcadis.com/arcgis",
        webScene: null,
        view: null,
        search: null,
        expandSearch: null,
        expandLayerList: null,
        layerList: null,
        legendList: null,
        expandLegend: null,
        baseMapGallery: null,
        expandBaseMapGallery: null,
        slice: null,
        expandSlice: null,
        expandHandleSlice: null,
        measurementLineWidget: null,
        measurementAreaWidget: null,
        expandMeasurementLine: null,
        expandMeasurementArea: null,
        expandHandleMeasurementLine: null,
        expandHandleMeasurementArea: null,
        screenshotBtnDiv: null,
        expandScreenshot: null,
        fullscreenDiv: null,
        fullscreen: null,
        elevationWidget: null,
        faqWidget: null,
        discussionToolWidget: null,
        discussionToolRefreshWidget: null,
        lightingWidget: null,
        expandLighting: null,
        discussionWidget: null,
        expandDiscussionTool: null,
        expandSlide: null,
        expandHandleSlide: null,
        slides: null,
        clientCanvas: {
          height: "",
          width: ""
        },
        fullHeight: 0,
        modalfaqShow: false,
        discussionLayer: null,
        opacityValue: 50,
        opacitySliderContainer: null,
        expandOpacitySliderWidget: null
      }
    },
    methods: {
      makeID: function(length) {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      },
      resetSlice: function(newValue, oldValue) {
        const current = this
        current.slice.viewModel.clearSlice()
      },
      initialSetup: function(current) {
        current.prefixId = current.makeID(5)
        esriConfig.portalUrl = current.portalURL
        var authInfo = new OAuthInfo({
          appId: current.appId,
          portalUrl: current.portalURL,
          popup: false
        });
        esriId.registerOAuthInfos([authInfo]);
        var portal = new Portal({
          url: current.portalURL
        });
        let portalItem = new PortalItem({
          id: current.webSceneId,
          portal: portal
        });
        portal.authMode = "immediate";
        portal.load().then(function(portalResult) {
          // block to add bespoke functionality for authentication ruleset if ever
          var userData = {
            userName: portalResult.user.username,
            email: portalResult.user.email,
            fullName: portalResult.user.fullName
          }
          // current.$store.commit("mapModule/saveUserName", portalResult.user.username)
          current.$store.commit("mapModule/saveUserEmail", portalResult.user.email)
          current.$store.commit("mapModule/saveUser", userData)
        });
        esriId
        .checkSignInStatus(current.portalURL + "/sharing/rest")
        .then(function (){
          current.webScene = new WebScene({
            portalItem: portalItem
          })
          current.webScene.load()
          .catch((error)=>{
            // error
          })
          .then(()=>{
            current.view = new SceneView({
              container: current.prefixId + "-viewDiv",
              map: current.webScene,
              highlightOptions: {
                color: [255, 255, 0, 1],
                haloColor: "white",
                haloOpacity: 0.9,
                fillOpacity: 0.2
              }
            })
            current.view.on("layerview-create-error", function(event) {
              if (event.layer.type == 'imagery-tile') {
                let tileLayer = new TileLayer({
                  url: event.layer.url,
                  title: "Aerial Imagery (March 2021)",
                  visible: false
                })
                current.webScene.add(tileLayer, 0)
              }
              else {
                console.log("Need additional Fix: Please contact AUS GIS Team");
              }
            });
            // console.log(current.webScene.loadError)
            current.setupWidgets(current)
          })

          current.$store.dispatch("mapModule/fetchDiscussionLayer").then(result=>{
            current.discussionLayer = result
            current.webScene.load()
              .catch((error)=>{
                // error
              })
              .then(function() {
                // remove elevation
                // current.webScene.ground.layers.forEach(function(layer) {
                //   layer.visible = false;
                // });
                current.view.map.layers.on("after-add", function(event){
                  if (current.webScene.loaded) {
                    current.$store.commit("mapModule/setMapWebSceneLoadFlag", true)
                  }
                });
                current.$store.commit("mapModule/saveMapWebScene", current.webScene)
                current.view.map.add(current.discussionLayer)

              })
          })

        })
        return;
      },
      setupWidgets: function(current) {
          // Top left Widget
        var homeWidget = new Home({
          view: current.view
        });
        current.view.ui.add(homeWidget, {
          position: "top-left",
          index: 0
        });
        // Right hand widgets
        current.search = new Search({
          view: current.view
        });
        current.expandSearch = new Expand({
          view: current.view,
          expandTooltip: "Search",
          collapseTooltip: "Search",
          content: current.search,
        });
        current.layerList = new LayerList({
          view: current.view,
          listItemCreatedFunction: function(event) {
            var item = event.item;
            item.actionsSections = [
              [{
                title: "Zoom to layer",
                className: "fa fa-search",
                id: "full-extent"
              }]
            ]
          }
        });
        current.layerList.on("trigger-action", function(event) {
          current.view.goTo(event.item.layer.fullExtent);
        });
        current.expandLayerList = new Expand({
          view: current.view,
          expandTooltip: "Layer List",
          collapseTooltip: "Layer List",
          content: current.layerList
        });
        var layersInfo = []
        var webSceneLayers = current.webScene.layers.toArray();
        webSceneLayers.forEach(function(item, i) {
          var layerItem = {}
          layerItem.layer = item
          layerItem.title = item.title
          layersInfo.push(layerItem)
        });
        current.legendList = new Legend({
          view: current.view,
          layersInfos: layersInfo
        });
        current.expandLegend = new Expand({
          view: current.view,
          expandTooltip: "Legend",
          collapseTooltip: "Legend",
          content: current.legendList,
        });
        current.basemapGallery = new BasemapGallery({
          view: current.view
        });
        current.expandBaseMapGallery = new Expand({
          view: current.view,
          expandTooltip: "Basemap Gallery",
          collapseTooltip: "Basemap Gallery",
          content: current.basemapGallery,
        });
        current.slice = new Slice({
          view: current.view
        });
        current.expandSlice = new Expand({
          view: current.view,
          expandIconClass: "fa fa-object-group",
          expandTooltip: "Slicer",
          collapseTooltip: "Slicer",
          content: current.slice
        });
        current.expandHandleSlice = watch(current.expandSlice, "expanded", function(newValue, oldValue) {
          current.slice.viewModel.clearSlice()
        });
        current.measurementLineWidget = new DirectLineMeasurement3D({
          view: current.view
        });
        current.measurementAreaWidget = new AreaMeasurement3D({
          view: current.view
        });
        current.expandMeasurementLine = new Expand({
          view: current.view,
          expandIconClass: "fa fa-ruler-horizontal",
          expandTooltip: "Measure distance between two points",
          collapseTooltip: "Measure distance between two points",
          content: current.measurementLineWidget,
        });
        current.expandMeasurementArea = new Expand({
          view: current.view,
          expandIconClass: "fa fa-ruler-combined",
          expandTooltip: "Measure area",
          collapseTooltip: "Measure area",
          content: current.measurementAreaWidget,
        });
        current.expandHandleMeasurementLine = watch(current.expandMeasurementLine, "expanded", function(newValue, oldValue) {
          current.measurementLineWidget.viewModel.clearMeasurement()
        });
        current.expandHandleMeasurementArea = watch(current.expandMeasurementArea, "expanded", function(newValue, oldValue) {
          current.measurementAreaWidget.viewModel.clearMeasurement()
        });
        current.screenshotBtnDiv = document.getElementById(current.prefixId + "-screenshotBtnDiv")
        current.expandScreenshot = new Expand({
          view: current.view,
          expandIconClass: "fa fa-camera",
          expandTooltip: "Screen Shot",
          collapseTooltip: "Screen Shot",
          content: current.screenshotBtnDiv
        })
        current.fullscreenDiv = document.getElementById(current.prefixId + "-fullscreenDiv")
        current.fullscreen = new Fullscreen({
          container: current.fullscreenDiv,
          view: current.view
        });
        document.getElementById(current.prefixId + "-environmentDiv").style.display = "block";
        current.lightingWidget = document.getElementById(current.prefixId + "-environmentDiv");
        current.expandLighting = new Expand({
          view: current.view,
          expandIconClass: "esri-icon-environment-settings",
          expandTooltip: "Daylight",
          collapseTooltip: "Daylight",
          content: current.lightingWidget
        })

        document.getElementById(current.prefixId + "-discussionDiv").style.display = "block";
        current.discussionWidget = document.getElementById(current.prefixId + "-discussionDiv");
        current.expandDiscussionTool = new Expand({
          view: current.view,
          expandIconClass: "fa fa-comment",
          expandTooltip: "Collaboration Tool",
          collapseTooltip: "Collaboration Tool",
          content: current.discussionWidget
        })

        document.getElementById(current.prefixId + "-elevationDiv").style.display = "block";
        current.elevationWidget = document.getElementById(current.prefixId + "-elevationDiv");
        document.getElementById(current.prefixId + "-FAQButton").style.display = "block";
        current.faqWidget = document.getElementById(current.prefixId + "-FAQButton");
        document.getElementById(current.prefixId + "-discussionToolFullView").style.display = "block";
        current.discussionToolWidget = document.getElementById(current.prefixId + "-discussionToolFullView");
        document.getElementById(current.prefixId + "-discussionToolRefresh").style.display = "block";
        current.discussionToolRefreshWidget = document.getElementById(current.prefixId + "-discussionToolRefresh");
        current.expandSlide = new Expand({
          view: current.view,
          expanded: true,
          expandIconClass: "fa fa-clone",
          expandTooltip: "Slides",
          collapseTooltip: "Slides",
          content: document.getElementById(current.prefixId + "-slidesDiv")
        })
        current.expandHandleSlide = watch(current.expandSlide, "expanded", function(newValue, oldValue) {
          if (newValue == true) {
            document.getElementById(current.prefixId + "-slidesDiv").style.visibility = "visible";
            document.getElementById(current.prefixId + "-slidesDiv").style.display = "block";
          } else {
            document.getElementById(current.prefixId + "-slidesDiv").style.visibility = "hidden";
            document.getElementById(current.prefixId + "-slidesDiv").style.display = "none";
          }
        })
        document.getElementById(current.prefixId + "-slidesDiv").style.bottom = current.fullHeight - current.clientCanvas.height + "px"
        document.getElementById(current.prefixId + "-slidesDiv").style.width = current.clientCanvas.width + "px"
        document.getElementById(current.prefixId + "-slidesDiv").style.visibility = "visible";
        document.getElementById(current.prefixId + "-slidesDiv").style.display = "block";
        current.slides = current.webScene.presentation.slides;
        current.slides.forEach((item, index)=>{
          current.createSlideUI(item)
        });

        // opacity widget
        document.getElementById(current.prefixId + "-opacitySliderDiv").style.display = "block";
        current.opacitySliderContainer = document.getElementById(current.prefixId +"-opacitySliderDiv");
        current.expandOpacitySliderWidget = new Expand({
          view: current.view,
          expandIconClass: "fa fa-adjust",
          expandTooltip: "Ground transparency",
          collapseTooltip: "Ground transparency",
          content: current.opacitySliderContainer
        })

        watch(current.expandOpacitySliderWidget, "expanded", function(newValue, oldValue) {
          if (newValue == true) {
            current.opacityValue = 50
          } else {
            current.opacityValue = 1
          }
          current.setOpacity()
        })

        current.view.ui.add(current.expandSearch,"top-right")
        current.view.ui.add(current.expandLayerList,"top-right")
        current.view.ui.add(current.expandLegend,"top-right")
        current.view.ui.add(current.expandBaseMapGallery,"top-right")
        current.view.ui.add(current.expandSlice,"top-right")
        current.view.ui.add(current.expandMeasurementLine,"top-right")
        current.view.ui.add(current.expandMeasurementArea,"top-right")
        current.view.ui.add(current.expandDiscussionTool,"top-right")
        current.view.ui.add(current.discussionToolRefreshWidget,"top-right")
        current.view.ui.add(current.discussionToolWidget,"top-right")
        current.view.ui.add(current.expandScreenshot,"top-right")
        current.view.ui.add(current.fullscreen,"top-right")
        current.view.ui.add(current.expandOpacitySliderWidget,"top-right")
        current.view.ui.add(current.expandLighting,"top-right")
        current.view.ui.add(current.expandSlide,"top-right")
        current.view.ui.add(current.faqWidget,"top-right")
        current.view.ui.add(current.elevationWidget,"bottom-right")


      },
      setOpacity: function() {
        const current = this
        current.webScene.basemap.baseLayers.items.forEach(function(layer) {
            layer.opacity = 1 -(current.opacityValue / 100)
          });
      },
      screenshotBtnClick: function() {
        const current = this
        var view = current.view
        var screenshotBtn = document.getElementById(current.prefixId + '-screenshotBtn')
        current.expandScreenshot.collapse()
        screenshotBtn.classList.add("active")
        var appMap = document.getElementById(current.prefixId + '-gis3dViewer')
        appMap.style.cursor = "crosshair";
        let area = null;
        let maskArea = null;
        var client = {}
        const dragHandler = current.view.on("drag", function(event) {
          event.stopPropagation();
          if (!client.x) {
            client.x = event.native.clientX
            client.y = event.native.clientY
          }
          if (event.action !== "end") {
            const xmin = current.clamp(
              Math.min(event.origin.x, event.x),
              0,
              view.width
            );
            const xmax = current.clamp(
              Math.max(event.origin.x, event.x),
              0,
              view.width
            );
            const ymin = current.clamp(
              Math.min(event.origin.y, event.y),
              0,
              view.height
            );
            const ymax = current.clamp(
              Math.max(event.origin.y, event.y),
              0,
              view.height
            );
            area = {
              x: xmin,
              y: ymin,
              width: xmax - xmin,
              height: ymax - ymin
            };
            maskArea = {
              x: client.x,
              y: client.y,
              width: xmax - xmin,
              height: ymax - ymin
            }
            current.setMaskPosition(maskArea);
          } else {
            dragHandler.remove();
            view
              .takeScreenshot({
                area: area,
                format: "png"
              })
              .then(function(screenshot) {
                current.showPreview(screenshot);

                document.getElementById(current.prefixId + '-downloadBtn').onclick = function() {
                  const text = document.getElementById(current.prefixId + '-textInput').value;
                  if (text) {
                    const dataUrl = current.getImageWithText(screenshot, text);
                    current.downloadImage(
                      current.webmap.portalItem.title + ".png",
                      dataUrl
                    );
                  } else {
                    current.downloadImage(
                      current.webmap.portalItem.title + ".png",
                      screenshot.dataUrl
                    );
                  }
                };

                screenshotBtn.classList.remove("active");
                appMap.style.cursor = "auto";
                current.setMaskPosition(null);
              });
          }
        });
      },
      clamp: function(value, from, to) {
        return value < from ? from : value > to ? to : value;
      },
      setMaskPosition: function(area) {
        const current = this
        var maskDiv = document.getElementById(current.prefixId + '-maskDiv')
        if (area) {
          maskDiv.classList.remove("hide");
          maskDiv.style.left = area.x + "px";
          maskDiv.style.top = area.y + "px";
          maskDiv.style.width = area.width + "px";
          maskDiv.style.height = area.height + "px";
        } else {
          maskDiv.classList.add("hide");
        }
      },
      showPreview: function(screenshot) {
        const current = this
        const screenshotDiv = document.getElementById(current.prefixId + "-screenshotDiv");
        screenshotDiv.classList.remove("hide");
        const screenshotImage = document.getElementById(current.prefixId + "-screenshotImage");
        screenshotImage.width = screenshot.data.width;
        screenshotImage.height = screenshot.data.height;
        screenshotImage.src = screenshot.dataUrl;
      },
      getImageWithText: function(screenshot, text) {
        const imageData = screenshot.data;
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.height = imageData.height;
        canvas.width = imageData.width;

        context.putImageData(imageData, 0, 0);
        context.font = "20px Arial";
        context.fillStyle = "#000";
        context.fillRect(
          0,
          imageData.height - 40,
          context.measureText(text).width + 20,
          30
        );

        context.fillStyle = "#fff";
        context.fillText(text, 10, imageData.height - 20);

        return canvas.toDataURL();
      },
      downloadImage: function(filename, dataUrl) {
        if (!window.navigator.msSaveOrOpenBlob) {
          const element = document.createElement("a");
          element.setAttribute("href", dataUrl);
          element.setAttribute("download", filename);
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        } else {
          const byteString = atob(dataUrl.split(",")[1]);
          const mimeString = dataUrl
            .split(",")[0]
            .split(":")[1]
            .split(";")[0];
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const blob = new Blob([ab], {
            type: mimeString
          });
          window.navigator.msSaveOrOpenBlob(blob, filename);
        }
      },
      screenshotCloseClick: function() {
        const current = this
        const screenshotDiv = document.getElementById(current.prefixId + '-screenshotDiv')
        screenshotDiv.classList.add("hide");
      },
      updateElevation: function(event) {
        const current = this
        current.webScene.ground.layers.forEach(function(layer) {
          layer.visible = event.target.checked;
        });
      },
      updateTimeOfDay: function(event) {
        const current = this
        var select = event.target;
        var optionValue = select.options[select.selectedIndex].value;
        var date = new Date()
        if (optionValue == 1) {
          date.setUTCHours(1);
        }
        if (optionValue == 2) {
          date.setUTCHours(4);
        }
        if (optionValue == 3) {
          date.setUTCHours(8);
        }
        if (optionValue == 4) {
          date.setUTCHours(10);
        }
        date.setUTCMinutes(0);
        date.setUTCSeconds(0);
        current.view.environment.lighting.date = date;
      },
      updateDirectShadows: function(event) {
        const current = this
        current.view.environment.lighting.directShadowsEnabled = !!event.target.checked;
      },
      createSlideUI: function(slide, placement) {
        const current = this
        var slideElement = document.createElement("div");
        slideElement.id = slide.id;
        slideElement.classList.add("slide");

        var slidesDiv = document.getElementById(current.prefixId + "-slidesDiv");
        if (placement === "first") {
          slidesDiv.insertBefore(slideElement, slidesDiv.firstChild);
        } else {
          slidesDiv.appendChild(slideElement);
        }

        var img = new Image();
        img.src = slide.thumbnail.url;
        img.title = slide.title.text;
        slideElement.appendChild(img);
        var title = document.createElement("div");
        title.innerText = slide.title.text;
        slideElement.appendChild(title);

        slideElement.addEventListener("click", function() {
          var slides = document.querySelectorAll(".slide");
          Array.from(slides).forEach(function(node) {
            node.classList.remove("active");
          });

          slideElement.classList.add("active");
          slide.applyTo(current.view);
        });
      },
      openDiscussionToolFullView: function () {
          window.open(appBaseUrl, "_blank");
      },
      refreshDiscussions: function() {
        const current = this
        current.$store.dispatch("mapModule/fetchDiscussionLayer").then(result=>{
          current.discussionLayer = result
          current.view.map.add(current.discussionLayer)
        })
      },
    },
    mounted() {
      const current = this
      current.fullHeight = Math.round(window.innerHeight / (100 / 100))
      current.clientCanvas.height = this.$refs.gis.clientHeight
      current.clientCanvas.width = this.$refs.gis.clientWidth
      current.initialSetup(current)
    }
  }
</script>
<style scoped>
  .gis3DViewer {
    /* height: 98vh !important;
    width: 98vw !important; */
    height: 100%;
    width: 100%;
  }
  .viewDiv3D {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
  }
  .screenshotDiv {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1;
  }
  .hide {
    display: none;
  }
  .active {
    background: #0079c1;
    color: #e4e4e4;
  }
  .screenshotCursor {
    cursor: crosshair;
  }
  .screenshotBtnDiv {
    padding: 10px;
    width: 260px;
    bottom: 15px;
    right: 10px;
    font-size: 14px;
    /* display: none; */
  }
  .maskDiv {
    position: absolute;
    background: rgba(255, 51, 0, 0.1);
    border: 2px dashed rgb(255, 51, 0);
  }
  .screenshotDiv > * {
    margin: 0.5em;
  }
  .elevationDiv {
    font-size: 12pt;
    padding: 10px;
    display: none;
    z-index: 1;
  }
  .environmentDiv {
    padding: 10px;
    width: 260px;
    display: none;
  }
  .discussionDiv {
    width: 400px;
    display: none;
  }
  .custom-faq-widget {
    z-index: 1;
    display: none;
    padding: 0.175rem 0.45rem;
  }
  .discussionToolFullView {
    z-index: 1;
    display: none;
    padding: 0.175rem 0.45rem;
  }
  .discussionToolRefresh {
    z-index: 1;
    display: none;
    padding: 0.175rem 0.45rem;
  }
  .slidesDiv {
    position: fixed;
    opacity: 0.9;
    padding-right: 10px;
    visibility: hidden;
    /* bottom: 15px; */
    left: 0;
    right: 0;
    text-align: center;
    /* width: 100vw; */
    max-width:100%;
    display: none;
    overflow-x: auto;
    white-space: nowrap;
    background: rgba(0, 0, 0, 0.2);
  }

  div.scrollmenu {
    background-color: #333;
    /* overflow: auto; */
    overflow-x: auto;
    white-space: nowrap;
  }

  div .slidesDiv{
    display: inline-block !important;
    color: white;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    font-size: 12px;
  }

  .slidesDiv::-webkit-scrollbar-track
  {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #333;
  }

  .slidesDiv::-webkit-scrollbar
  {
    width: 2px !important;
    background-color: #333;
  }

  .slidesDiv::-webkit-scrollbar-thumb
  {
    background-color: #6c757d;
    /* border: 2px solid #555555; */
  }
  /* Ground Transparency Widget */
  .opacitySliderDiv {
    padding: 10px;
    display: none;
    width: 250px;
  }
  /*Chrome*/
  @media screen and (-webkit-min-device-pixel-ratio:0) {
      input[type='range'] {
        overflow: hidden;
        width: 150px;
        -webkit-appearance: none;
        background-color: #E66200;
        border-radius: 8px;
      }

      input[type='range']::-webkit-slider-runnable-track {
        height: 15px;
        -webkit-appearance: none;
        color: #13bba4;
        margin-top: -1px;
      }

      input[type='range']::-webkit-slider-thumb {
        width: 15px;
        -webkit-appearance: none;
        height: 15px;
        cursor: ew-resize;
        background: #434343;
        box-shadow: -150px 0 0 150px #ffe2cc;
      }

  }
  /** FF*/
  input[type="range"]::-moz-range-progress {
    background-color: #ffe2cc;
  }
  input[type="range"]::-moz-range-track {
    background-color: #E66200;
  }
  /* IE*/
  input[type="range"]::-ms-fill-lower {
    background-color: #ffe2cc;
  }
  input[type="range"]::-ms-fill-upper {
    background-color: #E66200;
  }

</style>
<style>
  .slide .active {
    background-color: rgba(0, 121, 193, 0.5);
  }
  .slidesDiv .slide {
    cursor: pointer;
    margin-bottom: 6px;
    display: inline-block;
    padding-right: 10px;
    padding-left: 10px
  }

  .slidesDiv .slide .title {
    text-align: center;
  }

  .slidesDiv .slide.active img {
    box-shadow: 0px 0px 12px black;
    border-style: solid;
    border-width: thin;
    border-color: black;
  }
  .esri-icon-non-visible::before {
  content: "\e610";
  }
  .esri-icon-visible::before {
    content: "\e611";
  }
</style>
